import '../sdist/admin_tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './main.scss';

import Swiper from 'swiper';
import CookiesEuBanner from 'cookies-eu-banner';
import _, { isNumber } from 'lodash';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input'
import '@ryangjchandler/spruce'
import './js/photoswipe.js';

import './quill_snow.css';
import Quill from 'quill';

//import NewsApp from './js/NewsApp.vue';
import Countable from 'countable';
import 'htmx.org';
import Alpine from 'alpinejs';


window.Alpine = Alpine;



const TOOLBAR_CONFIG = [
  ['bold', 'italic', 'underline', 'link'],
  //[{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];


window.toggleExtraFieldChecked = function (initial, condition) {
  return {
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="value"]:checked');
        if (node) {
          this.value = node.value;
        }
      }
    },
  }
}


window.toggleCarrierType = function (initial, condition) {
  return {
    carrier_type: initial,
    isOpen() { return condition(this.carrier_type); },
    init() {
      if (Array.isArray(initial)) {
        this.carrier_type = [...this.$el.querySelectorAll('[x-model="carrier_type"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="carrier_type"]:checked');
        if (node) {
          this.carrier_type = node.value;
        }
      }
    },
  }
}


window.toggleCooperationAgreement = function (initial, condition) {
  return {
    cooperation_agreement_exists: initial,
    isOpen() { return condition(this.cooperation_agreement_exists); },
    init() {
      if (Array.isArray(initial)) {
        this.cooperation_agreement_exists = [...this.$el.querySelectorAll('[x-model="cooperation_agreement_exists"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="cooperation_agreement_exists"]:checked');
        if (node) {
          this.cooperation_agreement_exists = node.value;
        }
      }
    },
  }
}

window.Finance = function () {
  return {
    expenses: 0.0,
    income: 0.0,
    funding: 0.0,
    totalExpenses() {
      return this.expenses;
    },
    totalIncome() {
      return this.income + this.funding;
    },
    subtotalIncome() {
      return this.income;
    },
    totalBalance() {
      return this.income + this.funding - this.expenses;
    },
    unbalanced() {
      return (this.income + this.funding).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) != this.expenses.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },
    fundingOverLimit() {
      return this.funding > 2000.0;
    },
    maxFunding() {
      let lower_limit = Math.ceil(this.expenses * 0.9);
      return lower_limit > 2000 ? 2000.0: lower_limit;
    },
    fundingOverPercentage() {
      let funding = this.funding;
      let funding_limit = Math.ceil(this.expenses * 0.9);
      return funding > funding_limit;
    },
    calc() {
      let ExpenseValues = [...this.$el.querySelectorAll('[name$="expense"]:not(.will-be-deleted)')].map(node => parseFloat(node.value)).filter(i => isNumber(i) && !isNaN(i));
      let IncomeValues = [...this.$el.querySelectorAll('[name$="income"]:not(.will-be-deleted)')].map(node => parseFloat(node.value)).filter(i => isNumber(i) && !isNaN(i));
      let FundingValues = [...this.$el.querySelectorAll('[name$="funding"]')].map(node => parseFloat(node.value)).filter(i => isNumber(i) && !isNaN(i));
      this.expenses = ExpenseValues.reduce((a, b) => a + b, 0);
      this.income = IncomeValues.reduce((a, b) => a + b, 0)
      this.funding = FundingValues.reduce((a, b) => a + b, 0);

      console.log(this.expenses, this.income, this.funding, this.unbalanced());
    },
    init() {
      this.calc();
    }
  }
}


window.toggleInnerExtraFieldChecked = function (initial, condition) {
  return {
    innerValue: initial,
    isOpen() { return condition(this.innerValue); },
    init() {
      if (Array.isArray(initial)) {
        this.innerValue = [...this.$el.querySelectorAll('[x-model="innerValue"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="innerValue"]:checked');
        if (node) {
          this.innerValue = node.value;
        }
      }
    },
  }
}


window.toggleMultiFields = function (initial, condition, selectCondition) {
  return {
    innerValue: initial,
    isOpen() { return condition(this.innerValue); },
    isSelected() { return selectCondition(this.innerValue); },
    init() {
      if (Array.isArray(initial)) {
        this.innerValue = [...this.$el.querySelectorAll('[x-model="innerValue"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="innerValue"]:checked');
        if (node) {
          this.innerValue = node.value;
        }
      }
    },
  }
}


window.toggleFeedBackField = function () {
  return {
    value: '',
    rating: null,
    open: false,
    isOpen() { return this.value || this.open; },
    init() {
      let node = this.$el.querySelector('[x-model="value"]');
      if (node) {
        this.value = node.value;
      }

      let rating = this.$el.querySelector('[x-model="rating"]:checked');
      if (rating) {
        this.rating = rating.value;
      }
    }
  }
}

window.toggleExtraFieldSelected = function (initial, condition) {
  return {
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"] option[selected]')].map(node => node.value);
      } else {
        this.value = this.$el.querySelector('[x-model="value"] option[selected]').value;
      }
    },
  }
}


window.TotalRating = function (initialRating) {
  // {rating: {'fgdfgg': {'rating': x, 'weight': x}}}
  return {
    rated() { return _.filter(_.values(this.rating), o => o.rating !== null); },
    count() {
      console.log(this.rating);
      return this.rated().length;
    },
    total() {
      let totalWeights = 1; //_.sumBy(this.rated(), o => o.weight);
      let total = _.sumBy(this.rated(), o => o.weight * o.rating) / totalWeights;
      return total;
    },
    update(newRating) {
      // {label: 'fgdfgg', rating: x}
      if (newRating.label in this.rating) this.rating[newRating.label].rating = parseInt(newRating.rating)
    },
    ...initialRating
  }
}

window.CountingField = function (limit, direction, ressource) {
  return {
    limit: limit,
    direction: direction || 'down',
    ressource: ressource || 'all',
    counter: 0,
    count() { return (this.direction === 'down') ? this.limit - this.counter : this.counter },
    reached() { return (this.direction === 'down') ? this.counter === 0 : this.counter >= this.limit },
    init() {
      let node = this.$refs.field;
      if (node) {
        Countable.on(node, counter => { this.counter = counter[this.ressource]})
      }
    }
  }
}

window.InlineForms = function (params) {
  return {
    template: null,
    add() {
      let container = document.getElementById(this.container);
      container.insertAdjacentHTML('beforeend', this.template(this.TOTAL_FORMS));
      this.TOTAL_FORMS++;
      // focus new input
      let newInput = container.querySelector(`div[data-row="${this.TOTAL_FORMS}"] .container-focus input`);
      if (newInput) newInput.focus();
    },
    loadTemplate(id) {
      let template = document.getElementById(id);
      this.template = (id) => template.innerHTML.replace(/__prefix__/g, id).replace(/__number__/g, id + 1);
    },
  }
};

Alpine.store('preview', {
  active: null,
  fullscreen: false,
  app: {id: '', rank: '', name: ''},
  pages: [],

  setData(new_pages, app) {
    this.pages = new_pages;
    this.app = app;
    this.active = '#app';
  },

  activeUrl() {
    const active_page = this.pages.find(page => page.hash === this.active);
    return active_page ? active_page.url: null;
  },

});



Alpine.data('PreviewIframe', () => ({
    init() {
      Alpine.effect(() => {
        this.$el.contentWindow.location.replace(Alpine.store('preview').activeUrl());
      });
    }
  })
);


window.addEventListener('load', () => {
  try {
    var photogalleryOptions = {
      shareButtons: localizedShareButtons
    };
  } catch (ReferenceError) {
    var photogalleryOptions = {};
  }

  $.extend(photogalleryOptions, {
    itemFilter: function () {
      return $(this).parents('.slick-cloned').length === 0;
    },
    captionSelector: '.caption'
  });

  $('.gallery').photogallery(photogalleryOptions);

  bsCustomFileInput.init();

  $('#nav-tab a').on('click', function (e) {
    e.preventDefault()
    $(this).tab('show')
  });

  $('.popup__links').on('click', function (e) {
    e.preventDefault();
    var modal = $(this).data("target");
    $(modal).modal("show");
    $(modal + ' .modal-body').load(
      $(this).attr("href") + ' .text-page-container',
      function () {
      }
    );
  });


  $('body').scrollspy({
    target: '#navbar-main'
  });

  if ($('.swiper-slideshow .swiper-slide').length > 1) {

    var gallerySwiper = new Swiper('.swiper-slideshow', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        prevEl: '.swiper-slideshow__arrow--prev',
        nextEl: '.swiper-slideshow__arrow--next',
        disabledClass: 'swiper-slideshow__arrow--disabled',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    $('.swiper-slideshow').hover(function (params) {
      gallerySwiper.autoplay.stop();
    }, function (e) {
      gallerySwiper.autoplay.start();
    });
  }

  var file = document.getElementById("id_moodboard");
  if (file) {
    file.onchange = function () {
      if (file.files.length > 0) {

        document.getElementsByClassName('file-name')[0].innerHTML = file.files[0].name;

      }
    };
  }
});

(function () {


  function CountableField(textarea) {
    var countDisplay = document.getElementById(textarea.id + "_counter");
    var countDown = false;
    var minCount, maxCount;
    var hasQuill = textarea.getAttribute("data-quill") === 'true';
    var is_disabled = textarea.getAttribute("disabled") === 'disabled';

    if (textarea != null && countDisplay != null) {
      minCount = textarea.getAttribute("data-min-count");
      maxCount = textarea.getAttribute("data-max-count");
    }

    if (hasQuill && !is_disabled) {
      // hide textarea
      textarea.style.display = "none";

      // add editor container
      var quillContainer = document.createElement("div");
      quillContainer.setAttribute("id", textarea.id + "_container");
      quillContainer.setAttribute("class", "w-full bg-white mt-0.5");
      textarea.parentNode.insertBefore(quillContainer, textarea.nextSibling);

      // add editor div
      var quillEditor = document.createElement("div");
      quillEditor.setAttribute("id", textarea.id + "_editor");
      quillContainer.appendChild(quillEditor);

      // load data from textarea
      quillEditor.innerHTML = textarea.value;

      var quill = new Quill(quillEditor, {
        placeholder: 'Hier Text eingeben ...',
        modules: {
          toolbar: TOOLBAR_CONFIG
        },
        theme: 'snow'
      });

      quill.on('text-change', function (delta, oldDelta, source) {
        if (source === 'user') {
          Countable.count(quill.root.innerHTML, updateCharatersLeft, { stripTags: true });
          textarea.value = quill.root.innerHTML;
        }
      });

      Countable.count(quill.root.innerHTML, updateCharatersLeft, { stripTags: true });

    } else {
      Countable.on(textarea, updateCharatersLeft);
    }

    function updateCharatersLeft(counter) {
      var count = counter["all"];
      var left = maxCount - count;
      countDisplay.getElementsByClassName("text-count-current")[0].innerHTML = left;
      if (left < 0)
        countDisplay.className = "text-count text-is-over-max";
      else
        countDisplay.className = "text-count";
    }
  }

  document.addEventListener('DOMContentLoaded', function (e) {
    ;
    [].forEach.call(document.querySelectorAll('[data-count]'), CountableField)
  })
})()


Alpine.start();
